.page-template-diet-new {
    height: 100%;

    main{
        position: relative;
        // height: 100%;

        .view{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            z-index: 20;
        }

        .view-enter {
            transform: translateX(100%);
        }

        .view-enter-active {
            transform: translateX(0);
            transition: transform 500ms;
        }

        .view-exit {
            transform: translateX(0);
        }

        .view-exit-active {
            transform: translateX(100%);
            transition: transform 500ms;
        }

        .bg-grey{
            background-color: $light-grey;
        }

        .bg-white{
            background: $white;
            border-bottom: 1px solid $white;
        }

        .modalContainer{
            transition: transform 500ms;
            transform: translateX(0);
            height: 100%;
        }

        .showModal{
            transform: translateX(-50%);
            overflow: hidden !important;
        }

        // day plan new styles
        header{
            padding: 1rem 0;

            h1{
                font-size: rem-calc(34);
                font-weight: 400;
                text-transform: none;
                margin: 0 0 1rem 0;
            }

            .diet-phase{
                display: inline-block;
                font-size: rem-calc(18);
                padding: 0.125rem 0.5rem;
                border-radius: $borderRadius;
                margin: 0 0 1rem 0;

                &.start, &.exit{
                    border-color: $orange-yellow;
                    color: $orange-yellow;
                }

                &.detox, &.biedronka{
                    background: rgba($phaseDetoxColor, 0.15);
                    color: $phaseDetoxColor;
                }

                &.step{
                    background: rgba($phaseStepColor, 0.15);
                    color: $phaseStepColor;
                }

                &.balance{
                    background: rgba($phaseBalanceColor, 0.15);
                    color: $phaseBalanceColor;
                }

                &.fat_burning{
                    background: rgba($phaseFatBurningColor, 0.15);
                    color: $phaseFatBurningColor;   
                }

                &.stabilisation{
                    background: rgba($phaseStabilisationColor, 0.15);
                    color: $phaseStabilisationColor;   
                }
            }
        }

        .meal{
            position: relative;
            padding: 0 0 1rem 0;

            h2{
                font-size: rem-calc(24);
                font-weight: bold;
                margin: 0 0 1rem 0;
            }

            .white-box{
                padding: 0 1rem 1rem 1rem;
                overflow: hidden;

                @include media-breakpoint-up(md){
                    padding: 1rem;
                }

                .embed-responsive{
                    margin: 0 -1rem 1rem -1rem;
                    width: calc(100% + 2rem);

                    @include media-breakpoint-up(md){
                        margin: -1rem 0 -1rem -1rem;
                        width: calc(100% + 1rem);
                        height: calc(100% + 2rem);

                        &::before{
                            padding-top: 0;
                        }
                    }

                    .embed-responsive-item{
                        background-size: cover;
                        background-position: center;
                    }
                }

                .meal-title{
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin: 0 0 1rem 0;
                    min-height: rem-calc(56);
                }

                h3{
                    font-size: rem-calc(24);
                    font-weight: 700;
                    margin: 0;
                }

                p.BTW{
                    font-size: rem-calc(14);
                    color: $grey;

                    @include media-breakpoint-up(md){
                        font-size: rem-calc(16);
                    }

                    strong{
                        color: $primary;
                    }
                }

                hr{
                    border-top: 1px solid $light-grey2; 
                }

                .border-right {
                    border-right: 1px solid $light-grey2 !important;
                }
            } 
        }

        .summary{
            h3{
                font-size: rem-calc(20);
            }

            .alert-summary{
                text-align: center;
                border: 1px solid $primary;
                color: $font-color;
                font-size: rem-calc(14);
                margin: 0 0 2rem 0;
    
                h3{
                    font-size: rem-calc(24);
                }
    
                .border-right {
                    position: relative;
                    border-right: none !important;

                    &::before{
                        content: '';
                        width: 1px;
                        height: 30px;
                        background: $light-grey2;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        margin-top: -15px;
                    }
                }
            }
        }

        // meal view
        .top-bar{
            background: $white;

            h1{
                font-size: rem-calc(30);
                font-weight: 400;
                margin: 0;
                text-transform: none;
            }
        }

        .meal-navigation{
            font-family: 'Netto', sans-serif;
            position: sticky;
            top: 0;
            background: $white;
            padding: 0;
            z-index: 10;
            box-shadow: 0px 4px 6px rgba($black, 0.1);
            overflow-x: auto;

            &.active {
                transition: all 0.4s ease-in-out;
            }
            
            &.hidden {
                top: 0;
                transition: all 0.4s ease-in-out;
            }

            .container{
                padding: 0;
            }

            ul{
                text-align: center;
                white-space: nowrap;
                margin: 0;
                padding: 0;
                overflow-x: auto;

                @include media-breakpoint-up(sm) {
                    overflow: hidden;
                }

                .nav-item{
                    display: inline-block;
                }
                    
            }

            .nav-link{
                padding: 1rem;
                color: $grey;
                margin: 0;
                font-size: 1rem;
                white-space: nowrap;
                cursor: pointer;
                border-bottom: 2px solid $white;

                &:active, &:hover{
                    color: $grey;
                }

                &.active{
                    color: $primary;
                    border-color: $primary;
                    font-weight: bold;

                    &.detox, &.biedronka{
                        border-color: $phaseDetoxColor;
                        color: $phaseDetoxColor;
                    }
    
                    &.step{
                        border-color: $phaseStepColor;
                        color: $phaseStepColor;
                    }
    
                    &.balance{
                        border-color: $phaseBalanceColor;
                        color: $phaseBalanceColor;
                    }
    
                    &.fat_burning{
                        border-color: $phaseFatBurningColor;  
                        color: $phaseFatBurningColor;   
                    }
    
                    &.stabilisation{
                        border-color: $phaseStabilisationColor;
                        color: $phaseStabilisationColor;   
                    }
                } 
            } 
        }

        //meal-details
        .meal-details {
            padding-top: 0;
            padding-bottom: 0;
            position: relative;

            .meal-info{
                background: $white;

                @include media-breakpoint-up(md){
                    background: transparent;
                }

                .white-box{
                    padding: 0;
                    margin: 0;
                    background: transparent;
                    box-shadow: none;

                    @include media-breakpoint-up(md){
                        background: $white;
                        box-shadow: 0px 0px 10px rgba($black,0.15);
                        border-radius: 10px;
                        padding: 1rem;
                        margin: 1.5rem 0 0 0;
                        overflow: hidden;
                    
                        @include media-breakpoint-up(lg) {
                            padding: 1.5rem;
                        }
                    }
                }

                h1{
                    font-size: rem-calc(24);
                    text-align: center;
                    text-transform: none;
                    margin-bottom: 2rem;
                }
            }

            .embed-responsive{
                margin: 0 -15px;
                width: calc(100% + 30px);

                @include media-breakpoint-up(md){
                    margin: -24px;
                    width: calc(100% + 48px);
                }

                .video{
                    background-color: $grey;
                }

                .embed-responsive-item{
                    background-size: cover;
                    background-position: center;
                }
            }

            .alert-summary{
                text-align: center;
                border: 1px solid $primary;
                color: $font-color;
                font-size: rem-calc(12);
                margin: 2rem 0;

                @include media-breakpoint-up(md){
                    font-size: rem-calc(14);
                    padding: 1.25rem 1rem;
                }
    
                h3{
                    font-size: rem-calc(16);

                    @include media-breakpoint-up(md){
                        font-size: rem-calc(24);
                    }
                }
    
                .border-right {
                    position: relative;
                    border-right: none !important;

                    &::before{
                        content: '';
                        width: 1px;
                        height: 20px;
                        background: $light-grey2;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        margin-top: -10px;
                    }
                }
            }

            h2 {
                color: $black;
                font-size: 1.5rem;
                font-weight: 700;
                text-align: center;

                @include media-breakpoint-up(md){
                    text-align: left;
                }

                &.dishName{
                    font-size: rem-calc(30);
                    @include media-breakpoint-up(md){
                        margin-bottom: 2rem;
                    }
                }
            }

            .ingredients {
                font-size: 1rem;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    position: relative;
                    margin: 0 0 1rem 0;
                    padding-left: 1.5rem;
                    cursor: pointer;
                }
            }

            .btn-change {
                vertical-align: middle;
                display: inline-block;
                margin: 0 0.5rem 0 0;
                width: 1.25rem;
                height: 1.25rem;
                padding: 0;
                background: none;
                border: none;
                outline: none;

                .img-fluid {
                    vertical-align: top;
                }
            }

            .collapse, .collapsing{
                margin-left: -1.5rem;
            }

            .card {
                position: relative;
                margin-top: 1rem;
                
                border-radius: 10px;
                border: none;
                background: $very-light-orange;
                z-index: 0;

                .card-body {
                    padding: 1rem;
                    font-size: 1rem;
                    color: $primary;
                    text-align: center;

                    h4{
                        font-size: rem-calc(20);
                        color: $primary;
                    }

                    .ingredients {
                        text-align: left;
                        color: $font-color;
                        font-size: 1rem;
                        margin: 0;

                        li{
                            padding-left: 1.5rem;
                        }

                        button {
                            background: none;
                            border: none;
                            color: $font-color;
                        }
                    }
                }
            }

            .product-box{
                border: 1px solid $primary;
                border-radius: $borderRadius;
                display: flex;
                align-items: center;
                padding: 1rem;
                margin-top: 1rem;
            }

            h3{
                font-size: rem-calc(20);
            }

            .chef-advice{
                position: relative;
                background: $very-light-orange;
                border-radius: rem-calc(10);
                border: 1px solid $primary;
                padding: 0.625rem 1rem;
                color: $primary;
                font-size: 16px;
                margin: 0 0 1rem 0;

                div{
                    outline: none;
                }

                span.collapse-arrow {
                    position: absolute;
                    right: 1rem;
                    top: 0.75rem;
                    color: $primary ;
                    font-size: 1rem;
                    transition: all 0.3s ease-in-out;
        
                    &.open {
                        transform: rotate(180deg);
                    }
                }

                .collapse, .collapsing{
                    margin: 0;
                }

                h4{
                    margin: 1rem 0 0.75rem 0;
                }

                p{
                    color: $grey;
                    margin: 0 0 0.375rem;
                }

                .embed-responsive{
                    margin: 1rem 0 0.375rem 0;
                    width: 100%;
                    border-radius: $borderRadius;
    
                    .video{
                        background-color: $grey;
                    }
    
                    .embed-responsive-item{
                        background-size: cover;
                        background-position: center;
                    }
                }

                hr{
                    border-top: 1px solid $primary;
                }
            }

            .recipe {
                h2{
                    font-size: rem-calc(24);
                    font-weight: 700;
                    color: $black;
                    text-align: left;
                    margin-bottom: 1.5rem;

                    @include media-breakpoint-up(md){
                        margin-bottom: 1rem;
                    }
                }

                ol {
                    font-size: 1rem;
                    padding: 0 0 0 2.5rem;
                    list-style: none;
                    counter-reset: recipeCounter;


                    li {
                        position: relative;
                        counter-increment: recipeCounter;
                        padding-bottom: 1rem;
                        margin-bottom: 1rem;
                        border-bottom: 1px solid $light-grey2;


                        &:last-child{
                            border-bottom: none;
                            padding-bottom: 0;
                        }

                        &::before{
                            content: counter(recipeCounter);
                            position: absolute;
                            top: 0;
                            left: -2.5rem;
                            width: rem-calc(28);
                            height: rem-calc(28);
                            border-radius: 50%;
                            background: $primary;
                            text-align: center;
                            font-size: 1rem;
                            color: $white;
                            line-height: rem-calc(28);
                        }
                    }
                }
            }

            footer{
                position: sticky;
                bottom: 0;
                width: 100%;
                padding: 0.5rem 0 !important;
                background: $white;
                box-shadow: 0px -4px 6px rgba($black, 0.1);
            }
        }

        .change-meal{
            h3{
                font-size: rem-calc(20);
                color: $primary;
            }

            .meal-replacement{
                padding: 1rem !important;
                font-size: 1rem;
                
                .embed-responsive{
                    margin: -1rem 0 -1rem -1rem !important;
                    height: calc(100% + 2rem) !important;

                    .embed-responsive-item{
                        background-size: cover;
                        background-position: center;
                    }
                }
    
                .col-8{
                    height: 72px;
                   
                }
    
                .col-9{
                    max-height: 72px;
                    overflow: hidden;

                    // trim to 3 lines
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;  
                }
            }
        }

        .change-day{
            h3{
                font-size: rem-calc(20);
                color: $primary;
            }

            input[type="search"]{
                background: $white;
            }

            hr{
                margin: 2rem 0;
            }

            .event-meal{
                border-radius: $borderRadius;
                overflow: hidden;
                margin: 0 0 10px 0;
                font-size: 1rem;
                padding: 0;

                .embed-responsive{
                    margin: 0 !important;
                    border-radius: $borderRadius 0 0 $borderRadius;
                    // height: calc(100% + 2rem) !important;

                    .embed-responsive-item{
                        background-size: cover;
                        background-position: center;
                    }
                }

                .col-9{
                    height: 66px;
                    display: flex;
                    align-items: center;

                    @include media-breakpoint-up(lg){
                        height: 92px;
                    }

                    div{
                        max-height: 66px;
                        overflow: hidden;

                        // trim to 2 lines
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;  

                        @include media-breakpoint-up(md){
                            max-height: 92px;
                        }
                    }
                }
            }

            .white-box{
                &.holiday{
                    border: 1px solid $primary;

                    .SVGInline{
                        color: $primary;
                    }
                }
            }

            .list-of-dishes{
                margin: 0;
                padding: 0;
                list-style: none;
                display: inline;

                li{
                    display: inline;
                }
            }
        }

        .alert-new{
            position: relative;
            background: $white;
            box-shadow: 0px 4px 6px rgba($black, 0.1);
            border-radius: $borderRadius;
            padding: 1rem 1rem 1rem 1.5rem;
            margin: 0 0 1rem 0;
            font-size: 16px;
            color: $font-color;
            text-decoration: none !important;
            text-align: left;
            overflow: hidden;

            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 5px;
                height: 100%;
            }

            &.info{
                &::before{
                    background-color: $primary;
                }
            }

            &.success{
                &::before{
                    background-color: $secondary;
                }
            }

            &.danger{
                &::before{
                    background-color: $red;
                }
            }

            &.neutral{
                &::before{
                    background-color: $violet;
                }
            }

            h4{
                font-size: 1rem;
                font-weight: bold;
            }

            p{
                font-size: 1rem;
            }

            ul{
                font-size: 1rem;
                list-style: none;
                
                padding: 0;
                margin: 0;

                li{
                    &::before {
                        content: "-";
                    }
                }
            }
        }

    }
} 