.timeline-container {
    position: sticky;
    top: -51px;
    width: 100%;
    z-index: 10;
    
    &.active{
        top: 0;
        transition: all 0.4s ease-in-out;
    }

    &.hidden {
        transition: all 0.4s ease-in-out;
    }

    .container{
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
}

.timeline {
    padding: 0 !important;
    background: $white;
    border-bottom: 1px solid $light-grey;

    nav {
        position: relative;

        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 3rem;
            height: 100%;
            background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 1) 60%, rgba($white, 0) 100%);
            z-index: 2;

            @include media-breakpoint-up(sm) {
                width: 2rem;
                background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 1) 50%, rgba($white, 0) 100%);
            }
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 3rem;
            height: 100%;
            background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 40%, rgba($white, 1) 100%);
            z-index: 2;

            @include media-breakpoint-up(sm) {
                width: 2rem;
                background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 50%, rgba($white, 1) 100%);
            }
        }

        .timeline-arrow {
            box-shadow: none;
            border: none;
            outline: none;
            background: none;
            height: calc(100% - 4px);
            position: absolute;
            color: $grey;
            display: flex;
            align-items: center;
            z-index: 10;
            padding: 0 1rem;

            // @include media-breakpoint-up(sm) {
            //     display: flex;
            // }

            &.left {
                left: 0;
                
                @include media-breakpoint-up(sm) {
                    padding: 0 1rem 0 0;
                }
            }

            &.right {
                right: 0;
                
                @include media-breakpoint-up(sm) {
                    padding: 0 0 0 1rem;
                }
            }

            .SVGInline {
                svg {
                    font-size: 1.25rem;
                }
            }
        }

        ul {
            white-space: nowrap;
            margin: 0;
            padding: 0.5rem 0;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
                opacity: 0;
            }

            &::-webkit-scrollbar-thumb {
                background: $white;
                opacity: 0;
            }

            &.smooth {
                scroll-behavior: smooth;
            }

            .nav-item {
                display: inline-block;
                
                &:first-child{
                    margin-left: 2rem;
                }

                &:last-child{
                    margin-right: 2rem;
                }

                .nav-link {
                    position: relative;
                    min-width: 5.5em;
                    text-align: center;
                    border-radius: $borderRadius;
                    color: $grey;
                    margin: 0;
                    white-space: nowrap;
                    cursor: pointer;
                    font-size: rem-calc(12);

                    &.clicked {
                        background: $light-grey;
                    }

                    &.placeholder {
                        color: transparent;
                    }

                    &::before{
                        content: '';
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        left: 0;
                        bottom: -8px;
                        background: $light-grey;
                    }

                    &.active {
                        background: $light-grey;
                    }

                    &.detox, &.biedronka{
                        &::before{
                            background: $phaseDetoxColor;
                        }

                        &.active {
                            background: $phaseDetoxColor;
                            color: $white;
                        }
                    }

                    &.fat_burning{
                        &::before{
                            background: $phaseFatBurningColor;
                        }

                        &.active {
                            background: $phaseFatBurningColor;
                            color: $white;
                        }
                    }

                    &.stabilisation{
                        &::before{
                            background: $phaseStabilisationColor;
                        }

                        &.active {
                            background: $phaseStabilisationColor;
                            color: $white;
                        }
                    }

                    &.step{
                        &::before{
                            background: $phaseStepColor;
                        }

                        &.active {
                            background: $phaseStepColor;
                            color: $white;
                        }
                    }

                    &.balance{
                        &::before{
                            background: $phaseBalanceColor;
                        }

                        &.active {
                            background: $phaseBalanceColor;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
